export default {
  "common": {
    "username": "Username",
    "password": "Password",
    "login": "Login",
    "logout": "Logout",
    "setting": "Setting",
    "submit": "Submit",
    "re_login": "Login again",
    "print_in_warehouse_order": "Print Inbound Sheet",
    "remember_me": "记住我",
    "p0_is_required": "{0} is required",
    "p0_is_not_complete": "{0} is not completed.",
    "p0_must_be_at_least_p1_characters": "{0} requires at least {1} character",
    "p0_up_to_p1_characters": "{0}最多{1}个字符",
    "p0_cannot_be_empty_or_0": "{0} cannot be empty or 0",
    "p0_incorrect_format": "{0} format incorrect",
    "the_number_of_successes_is_p0": "成功数量为{0}",
    "the_number_of_fail_is_p0": "失败数量为{0}",
    "fail_order": "失败订单",
    "print": "Print",
    "no_data": "No Data",
    "succeed": "Succeed",
    "the_format_of_the_uploaded_file_is_not_supported": "The format of uploaded file is unsupported.",
    "failed": "Failed",
    "are_you_sure": "Are you sure?",
    "successfully_canceled": "Cancelled successfully",
    "days": "天",
    "back_step": "Last Step",
    "next_step": "Next Step",
    "save_and_next_step": "Save & Next Step",
    "backtrack": "Back",
    "total": "Total",
    "item": "Item",
    "max_length_side": "The longest side",
    "second_length_side": "Second longest side",
    "least_length_side": "The shortest side",
    "upload": "Upload",
    "upload_file": "Upload file",
    "confirm_in_plan": "Confirm inbound plan",
    "confirm": "Confirm",
    "confirm_the_import": "确认导入",
    "import": "导入",
    "cancel": "Cancel",
    "close": "Close",
    "search": "Search",
    "edit": "Edit",
    "query": "Query",
    "save": "Save",
    "saved_successfully": "Saved Successfully",
    "details": "Details",
    "exception": "Exception",
    "country": "Country",
    "destination_country": "Destination Country",
    "province": "Province/State",
    "city": "City",
    "county": "County",
    "create": "Create",
    "delete": "Delete",
    "please_select": "Please Select",
    "403_cue_words": "Sorry, you have no authority on this page.",
    "404_cue_words": "Sorry, the page does not exist.",
    "500_cue_words": "Sorry, there are some problems with the server.",
    "back_home": "Back to home page",
    "remove": "Remove",
    "remove_success": "Removed Successfully.",
    "delete_success": "Deleted Successfully",
    "postcode": "Postcode",
    "amend_success": "Modified Successfully",
    "operation": "Operation",
    "add": "Add",
    "added": "Added",
    "add_success": "Added Successfully",
    "create_success": "Created Successfully",
    "completed": "Completed",
    "select": "Select",
    "selected": "Selected",
    "upload_img": "Upload image",
    "bulk_print_box_label": "Print carton label in bulk",
    "print_box_label": "Print box label",
    "Are_you_sure_to_delete_this_piece_of_data": "Confirm to delete this data?",
    "Are_you_sure_to_delete_p0": "你确定要删除{0}吗?",
    "are_you_sure_cancel": "Confirm to cancel?",
    "p0_must_be_an_integer": "{0} must be an integer",
    "close_current": "Colse current section",
    "close_others": "Close other sections",
    "close_all": "Close all sections",
    "data_modification_succeeded": "Data successfully repaired.",
    "system_automatically_helps_repair": "System helps repairing automatically?",
    "current_size_fill_in_error": "Current size is filled in incorrectly.",
    "uploaded_img_file_format_incorrect": "Please upload image with .jpeg/.png/.jpg format.",
    "submit_confirmation": "Confirm submittion",
    "refresh": "Refresh",
    "top_up": "Recharge",
    "input_is_not_in_the_correct_format": "Incorrect format, only digits, letters, '-', '_', '/' and '\\' allowded.",
    "the_input_length_is_too_long": "Over length",
    "user_defined_box_no": "User-defined carton number",
    "set_user_defined_box_no_succeed": "Setting user-defined carton number successfully",
    "batch_print": "Batch Print",
    "se_outer_box_label": "SE carton label",
    "product_label_manage": "Product label management",
    "product_statistics": "产品统计",
    "product_no": "货号",
    "total_product": "产品总数",
    "pdf_upload_hint": "注意: 上传PDF文件时, 需保持高宽比例为3:2或者2:1",
    "export": "导出",
    "i_already_know": "我已知晓",
    "cautions": "注意事项",
    "export_product_in_the_warehouse_time": "导出产品库龄",
    "yes": "是",
    "no": "否",
    "version_tips": "当前为非正式版本",
    "copy": "Copy",
    "change_SESKU": "更换SESKU",
    "hint": "提示",
    "nothing": "无",
    "error_ause": "错误原因",
    "optional": "可选填"
  }
}